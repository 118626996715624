<template>
  <div class="app-container">
    <div class="outer">
      <GoodsBase />
    </div>
  </div>
</template>

<script>
import './style.scss';
import GoodsBase from './components/goodsBase/index.vue';

export default {
  components: {
    GoodsBase,
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="scss">
</style>
