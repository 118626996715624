var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "outer" }, [_c("GoodsBase")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }